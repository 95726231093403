import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToLastPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if a last visited page is stored in localStorage
    const lastVisitedPage = localStorage.getItem('lastVisitedPage');
    const token = localStorage.getItem("accessToken");
    
    if (!token) {
        navigate('/');
      }

    // Redirect to the last visited page if it's found and the user is on the home page
    if (token && lastVisitedPage && window.location.pathname === '/') {
      navigate(lastVisitedPage);
    }
  }, [navigate]);

  return null; // This component does not render anything
};

export default RedirectToLastPage;
