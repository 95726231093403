import { Outlet } from "react-router-dom";
import SidebarNav from "./SidebarNav"; // Your navigation component

const Layout = () => {
  return (
    <div className="flex h-screen">
      {/* Sidebar Navigation */}
      <SidebarNav />

      {/* Main Content Area */}
      <div className="flex-1 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
