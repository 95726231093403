// import React, { useEffect, useState } from "react";

// import { faUser } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fetchUserReferralsForOnboarding } from "../services/Api";

// const ReferrerCard = ({ handleYes }) => {

//   const [showMessage, setShowMessage] = useState(false);
//   const referrerMobileNumber = localStorage.getItem("referrerMobileNumber");
//   const restaurantId = localStorage.getItem("restaurant_Id");
//   const [referralId, setReferralId] = useState("");
//   const [mobileNumberReferrer, setMobileNumberReferrer] = useState("");

//   useEffect(() => {
//     const fetchReferrals = async () => {
//       try {
//         const referrals = await fetchUserReferralsForOnboarding(restaurantId);
//         setReferralId(referrals.id)
//         setMobileNumberReferrer(referrals.referrerMobileNumber)
//       } catch (error) {
//         console.error("Error fetching referrals:", error);
//       } finally {
//       }
//     };

//     fetchReferrals();
//   }, [restaurantId]);

//   // Function to handle the 'No' button click
//   const handleNo = () => {
//     setShowMessage(true); // Trigger the custom message
//   };

//   // Function to handle the 'Close' button click
//   const handleClose = () => {
//     setShowMessage(false); // Hide the thank you message
//   };

//   return (
//     <div>
//       <div>
//         {referralId ? (
//           <>
//             <div className="thank-you-message text-center mt-4">
//               <h3 className="text-l text-center font-bold text-green-600">You're referred by {mobileNumberReferrer}</h3>
//               <p className="text-sm text-gray-800 mt-2">Thank you for providing the referrer details!</p>
//             </div>
//           </>
//         ) : (
//           <>
//             {referrerMobileNumber ? (
//               <div className="thank-you-message text-center mt-4">
//                 <h3 className="text-l text-center font-bold text-green-600">You're referred by +91{referrerMobileNumber}</h3>
//                 <p className="text-sm text-gray-800 mt-2">Thank you for providing the referrer details!</p>
//               </div>
//             ) : (
//               <>
//                 {!showMessage ? (
//                   <div className="refer flex justify-between items-center border p-3 rounded-lg mt-6">
//                     <div className="flex gap-2">
//                       <FontAwesomeIcon icon={faUser} className="icons text-xl" />
//                       <p className="text-black font-semibold">
//                         Did someone refer you to this platform?
//                       </p>
//                     </div>
//                     <div className="flex gap-2">
//                       <div
//                         className="yes font-semibold px-3 py-1 border rounded-xl cursor-pointer text-xs"
//                         style={{ borderColor: "#2ca721" }}
//                         onClick={handleYes}
//                       >
//                         Yes
//                       </div>
//                       <div
//                         className="yes font-semibold px-3 py-1 border rounded-xl cursor-pointer text-xs"
//                         style={{ borderColor: "#2ca721" }}
//                         onClick={handleNo}
//                       >
//                         No
//                       </div>
//                     </div>
//                   </div >
//                 ) : (
//                   <div className="thank-you-message text-center mt-4">
//                     {/* <button onClick={handleClose} className="top-0 right-0 text-green-500 text-sm font-bold px-3 py-1 cursor-pointer hover:bg-red-100 rounded" > X </button> */}
//                     <h3 className="text-xl text-center font-bold text-green-600">Thank you!</h3>
//                     <p className="text-sm text-gray-800 mt-2">
//                       No referrer? No problem! You're an original. 🎉
//                     </p>
//                     <p className="text-sm text-gray-600 mt-1">
//                       We're excited to have you here 😊
//                     </p>
//                     {/* Close Button */}
//                   </div>
//                 )
//                 }
//               </>
//             )}
//           </>
//         )

//         }
//       </div>
//     </div >

//   );
// };

// export default ReferrerCard;

// // import React, { useEffect, useState } from "react";
// // import { faUser } from "@fortawesome/free-solid-svg-icons";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { fetchUserReferralsForOnboarding } from "../services/Api";

// // const ReferrerCard = ({ handleYes }) => {
// //   const [state, setState] = useState({
// //     showMessage: false,
// //     referralId: "",
// //     mobileNumber: localStorage.getItem("referrerMobileNumber") || "",
// //     restaurantId: localStorage.getItem("restaurant_Id") || "",
// //   });

// //   useEffect(() => {
// //     const fetchReferrals = async () => {
// //       try {
// //         const referrals = await fetchUserReferralsForOnboarding(state.restaurantId);
// //         setState((prevState) => ({
// //           ...prevState,
// //           referralId: referrals.id,
// //           mobileNumber: referrals.referrerMobileNumber || prevState.mobileNumber,
// //         }));
// //       } catch (error) {
// //         console.error("Error fetching referrals:", error);
// //       }
// //     };

// //     fetchReferrals();
// //   }, [state.restaurantId]);

// //   // Toggle the custom message visibility on "No" click
// //   const handleNo = () =>
// //     setState((prevState) => ({ ...prevState, showMessage: true }));

// //   // Close the thank you message
// //   const handleClose = () =>
// //     setState((prevState) => ({ ...prevState, showMessage: false }));

// //   return (
// //     <div>
// //       {state.referralId || state.mobileNumber ? (
// //         <ThankYouMessage mobileNumber={state.mobileNumber} />
// //       ) : (
// //         <NoReferrerMessage
// //           showMessage={state.showMessage}
// //           handleYes={handleYes}
// //           handleNo={handleNo}
// //           handleClose={handleClose}
// //         />
// //       )}
// //     </div>
// //   );
// // };

// // // ThankYouMessage component for modularity
// // const ThankYouMessage = ({ mobileNumber }) => (
// //   <div className="thank-you-message text-center mt-4">
// //     <h3 className="text-l text-center font-bold text-green-600">
// //       You're referred by {mobileNumber}
// //     </h3>
// //     <p className="text-sm text-gray-800 mt-2">
// //       Thank you for providing the referrer details!
// //     </p>
// //   </div>
// // );

// // // NoReferrerMessage component for modularity
// // const NoReferrerMessage = ({ showMessage, handleYes, handleNo, handleClose }) => (
// //   <>
// //     {!showMessage ? (
// //       <div className="refer flex justify-between items-center border p-3 rounded-lg mt-6">
// //         <div className="flex gap-2">
// //           <FontAwesomeIcon icon={faUser} className="icons text-xl" />
// //           <p className="text-black font-semibold">
// //             Did someone refer you to this platform?
// //           </p>
// //         </div>
// //         <div className="flex gap-2">
// //           <div
// //             className="yes font-semibold px-3 py-1 border rounded-xl cursor-pointer text-xs"
// //             style={{ borderColor: "#2ca721" }}
// //             onClick={handleYes}
// //           >
// //             Yes
// //           </div>
// //           <div
// //             className="yes font-semibold px-3 py-1 border rounded-xl cursor-pointer text-xs"
// //             style={{ borderColor: "#2ca721" }}
// //             onClick={handleNo}
// //           >
// //             No
// //           </div>
// //         </div>
// //       </div>
// //     ) : (
// //       <div className="thank-you-message text-center mt-4">
// //         <h3 className="text-xl text-center font-bold text-green-600">Thank you!</h3>
// //         <p className="text-sm text-gray-800 mt-2">
// //           No referrer? No problem! You're an original. 🎉
// //         </p>
// //         <p className="text-sm text-gray-600 mt-1">
// //           We're excited to have you here 😊
// //         </p>
// //       </div>
// //     )}
// //   </>
// // );

// // export default ReferrerCard;





import React, { useEffect, useState } from "react";
import { faUser, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReferrerModal from "./ReferrerModal"; // Import the modal component
import { fetchUserReferralsForOnboarding } from "../services/Api";

const ReferrerCard = ({ handleYes }) => {
  const [state, setState] = useState({
    showMessage: false,
    referralId: "",
    mobileNumber: localStorage.getItem("referrerMobileNumber") || "",
    restaurantId: localStorage.getItem("restaurant_Id") || "",
    showModal: false, // Track modal visibility for editing referral
  });

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const referrals = await fetchUserReferralsForOnboarding(state.restaurantId);
        setState((prevState) => ({
          ...prevState,
          referralId: referrals.id,
          mobileNumber: referrals.referrerMobileNumber || prevState.mobileNumber,
        }));
        // Save mobileNumber in localStorage to persist after refresh
        // localStorage.setItem("referrerMobileNumber", referrals.referrerMobileNumber || prevState.mobileNumber);
      } catch (error) {
        console.error("Error fetching referrals:", error);
      }
    };

    fetchReferrals();
  }, [state.restaurantId]);

  // Toggle the custom message visibility on "No" click
  const handleNo = () =>
    setState((prevState) => ({ ...prevState, showMessage: true }));

  // Close the thank you message
  const handleClose = () =>
    setState((prevState) => ({ ...prevState, showMessage: false }));

  // Toggle the referral modal
  const toggleModal = () =>
    setState((prevState) => ({ ...prevState, showModal: !prevState.showModal }));

  // Handle successful submission of referral
  const handleReferralSuccess = (mobileNumber) => {
    setState((prevState) => ({
      ...prevState,
      mobileNumber,
      showModal: false,
    }));
    localStorage.setItem("referrerMobileNumber", mobileNumber); // Save to localStorage
  };

  return (
    <div>
      {state.referralId || state.mobileNumber ? (
        <ThankYouMessage
          mobileNumber={state.mobileNumber}
          toggleModal={toggleModal} // Pass the toggle function to allow editing
        />
      ) : (
        <NoReferrerMessage
          showMessage={state.showMessage}
          handleYes={handleYes}
          handleNo={handleNo}
          handleClose={handleClose}
        />
      )}

      {/* Render the modal if showModal is true */}
      {state.showModal && (
        <ReferrerModal
          toggleReferModal={toggleModal}
          onClickSuccess={handleReferralSuccess}
          initialMobileNumber={state.mobileNumber} // Pass initial number for editing
        />
      )}
    </div>
  );
};

// ThankYouMessage component with Edit button for modularity
const ThankYouMessage = ({ mobileNumber, toggleModal }) => (
  <div className="thank-you-message flex items-center justify-center gap-10 text-center mt-4">
    <div className="">
    <h3 className="text-l text-center font-bold text-green-600">
      You're referred by {mobileNumber}
    </h3>
    <p className="text-sm text-gray-800 mt-2">
      Thank you for providing the referrer details!
    </p>
    </div>
    {/* Add Edit button */}
    <button
      onClick={toggleModal}
      className="text-blue-500 text-sm font-semibold mt-0 underline flex items-center justify-center"
    >
      <FontAwesomeIcon icon={faEdit} className="mr-1 text-green-600" />
    </button>
  </div>
);

// NoReferrerMessage component for modularity
const NoReferrerMessage = ({ showMessage, handleYes, handleNo, handleClose }) => (
  <>
    {!showMessage ? (
      <div className="refer flex justify-between items-center border p-3 rounded-lg mt-6">
        <div className="flex gap-2">
          <FontAwesomeIcon icon={faUser} className="icons text-xl" />
          <p className="text-black font-semibold">
            Did someone refer you to this platform?
          </p>
        </div>
        <div className="flex gap-2">
          <div
            className="yes font-semibold px-3 py-1 border rounded-xl cursor-pointer text-xs"
            style={{ borderColor: "#2ca721" }}
            onClick={handleYes}
          >
            Yes
          </div>
          <div
            className="yes font-semibold px-3 py-1 border rounded-xl cursor-pointer text-xs"
            style={{ borderColor: "#2ca721" }}
            onClick={handleNo}
          >
            No
          </div>
        </div>
      </div>
    ) : (
      <div className="thank-you-message text-center mt-4">
        <h3 className="text-xl text-center font-bold text-green-600">Thank you!</h3>
        <p className="text-sm text-gray-800 mt-2">
          No referrer? No problem! You're an original. 🎉
        </p>
        <p className="text-sm text-gray-600 mt-1">
          We're excited to have you here 😊
        </p>
      </div>
    )}
  </>
);

export default ReferrerCard;
