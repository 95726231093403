import React from "react";
import Verified from "../images/verified.webp";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RequiredRegistrationDetails = ({ toggleModal }) => {
  return (
    <div className="fixed inset-0 flex items-center mt-20 justify-center bg-black bg-opacity-50 z-50 w-screen">
      <div className="bg-white rounded-lg shadow-lg p-5 w-11/12 max-w-md mx-auto relative z-50">
        <div className="mb-2 flex items-center justify-center">
          <img src={Verified} alt="Verification" className="w-54 h-24 rounded"/>
        </div>
        <h2 className="text-xl font-semibold mb-2 text-center">
          Please be ready with the following for a smooth registration
        </h2>
        <hr className="border-t border-gray-300 -mx-5"/>
        <ul className="space-y-2 mt-3">
          <li className="flex items-center">
            <FontAwesomeIcon icon={faChevronDown} className="icons me-3" />
            <span>PAN card</span>
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faChevronDown} className="icons me-3" />
            <span>
              FSSAI license{" "}
              <span className="text-gray-500 block text-sm">
                Don't have a FSSAI license?
              </span>
            </span>
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faChevronDown} className="icons me-3" />
            <span>Bank account details</span>
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faChevronDown} className="icons me-3" />
            <span>Menu details and one dish image</span>
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faChevronDown} className="icons me-3" />
            <span>
              GST number, if applicable{" "}
              <span className="text-gray-500 block text-sm">
                Not GST registered?
              </span>
            </span>
          </li>
        </ul>
        <hr className="border-t border-gray-300 mt-5 -mx-5" />
        <div className="flex justify-center mt-3">
          <button
            onClick={toggleModal}
            className="okay-btn text-white py-2 px-6 rounded-lg"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};
export default RequiredRegistrationDetails;
