import React from "react";
import { faFileAlt, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RequiredRegistrationCard = ({ onClickCard }) => {
  return (
    <div
      className="docNotification flex justify-between items-center border p-3 rounded-lg cursor-pointer"
      onClick={onClickCard}
    >
      <div className="flex gap-2">
        <FontAwesomeIcon icon={faFileAlt} className="icons text-xl" />
        <span className="text-black font-semibold me-8">
          Documents required for registration
        </span>
      </div>
      <div>
        {/* <FontAwesomeIcon icon={faPlay} className="icons text-base" /> */}
      </div>
    </div>
  );
};

export default RequiredRegistrationCard;
