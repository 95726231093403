import React from "react";

const LogoutModel = ({ onClickCancel, onClickLogout }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-screen">
      <div className="bg-white rounded-lg shadow-lg p-5 w-11/12 max-w-md mx-auto">
        <h2 className="text-xl font-semibold mb-3 text-center">
          Are you sure you want to logout?
        </h2>
        <div className="flex justify-center space-x-4 mt-5">
          <button
            className="bg-red-500 text-white py-2 px-6 rounded-lg w-1/2 text-center hover:bg-red-600"
            onClick={onClickLogout}
          >
            Yes, Logout
          </button>
          <button
            className="bg-gray-300 text-black py-2 px-6 rounded-lg w-1/2 text-center hover:bg-gray-400"
            onClick={onClickCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModel;

