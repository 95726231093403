import React from 'react';
import food from '../images/food.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const FoodImages = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const guidelines = [
    "Make sure all details are visible.",
    "Image should not be blurry.",
    "Image should not be cropped."
  ];

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="fixed inset-0" onClick={onClose}></div>
      <div className="bg-white rounded-lg overflow-hidden shadow-xl w-full max-w-lg mx-4 sm:mx-auto">
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-lg font-bold">Image upload guidelines</h2>
          <button className="cursor-pointer text-2xl" onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="p-4">
          <ul className="space-y-4">
            {guidelines.map((guideline, index) => (
              <li key={index} className="flex items-center space-x-4">
                <img
                  src={food}
                  alt={`Guideline ${index + 1}`}
                  className="w-32 h-24 object-cover"
                />
                <span className="text-sm sm:text-base">{guideline}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="p-4 border-t flex justify-end">
          <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
            onClick={onClose}
          >
            Upload now
          </button>
        </div>
      </div>
    </div>
  );
};

export default FoodImages;