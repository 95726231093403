// import React, { useState, useRef } from 'react';
// import Logo from '../images/logo.webp';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom';
// import { logoutService } from '../services/LogoutService';
// import LogoutModel from "./LogoutModel";
// import { sendOtp } from '../services/Api';
// import { handleError } from '../services/errorHandler';

// function SignDocument() {
//   const navigate = useNavigate();

//   const [showLogoutModal, setShowLogoutModal] = useState(false);
//   const [showOtpModal, setShowOtpModal] = useState(false);  // State to control OTP modal visibility
//   const [isChecked, setIsChecked] = useState(false);
//   const [otp, setOtp] = useState(Array(6).fill(''));
//   const inputRefs = useRef(new Array(6).fill(null));
//   const handleLogoutClick = () => setShowLogoutModal(true);
//   const restaurantId = localStorage.getItem("restaurant_Id");

//   const confirmLogout = () => {
//     setShowLogoutModal(false);
//     logoutService('Session');
//   };

//   const handleCheckboxChange = () => {
//     setIsChecked(!isChecked);
//   };

//   const handleSendOtp = async (e) => {
//     e.preventDefault();
//     try {
//       await sendOtp(restaurantId);
//       setShowOtpModal(true);
//       // console.log('OTP has been sent to', phone);
//     } catch (error) {
//       handleError(error);
//     }
//   };

//   // Handle OTP input change
//   const handleOtpChange = (index, value) => {
//     if (value.length === 1 && /^\d*$/.test(value)) {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);

//       if (index < 5) {
//         inputRefs.current[index + 1]?.focus();  // Focus on the next input field
//       }
//     } else if (value === '') {
//       const newOtp = [...otp];
//       newOtp[index] = '';  // Clear the current input
//       setOtp(newOtp);
//       inputRefs.current[index]?.focus();  // Stay on the same field
//     }
//   };

//   // Handle backspace key press in OTP fields
//   const handleKeyDown = (index, event) => {
//     if (event.key === 'Backspace') {
//       const newOtp = [...otp];
//       newOtp[index] = '';  // Clear current digit only

//       // Update the OTP state
//       setOtp(newOtp);

//       // Move focus to the previous input, but don't clear it
//       if (index > 0 && otp[index] === '') {
//         inputRefs.current[index - 1]?.focus();
//       }
//     }
//   };

//   const handleSignClick = () => {
//     setShowOtpModal(false);
//     navigate('/complete-sign'); // Replace '/complete-sign' with your actual route
//   };

//   return (
//     <div className="bg-gray-100 min-h-screen flex flex-col">
//       {" "}
//       {/* Ensure full height and background */}
//       <header className="w-full bg-white shadow-md flex items-center justify-between p-3">
//         <div className="flex items-center">
//           <img src={Logo} alt="Logo" className="h-12" />
//         </div>
//         <div className="flex items-center">
//           <button
//             className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
//             onClick={handleLogoutClick}
//           >
//             Logout
//           </button>
//         </div>
//       </header>
//       <main className="flex-grow flex flex-col items-center mt-5 w-full">
//         <div className=" shadow-lg rounded-lg overflow-hidden flex flex-col items-center">
//           {/* Display the PDF with full width */}
//           <div className="w-full">
//             <iframe
//               src={
//                 "https://s3.ap-south-1.amazonaws.com/merchant-assets.thegenie.in/restaurant/MerchantAgreements/dummyRestaurant/The+Genie+Merchant+Onboarding+Documents.pdf"
//               }
//               title="Document"
//               className="w-full h-screen border-0"
//             ></iframe>
//           </div>

//           <div className="w-full p-6 flex flex-col items-center bg-gray-100">
//             {" "}
//             {/* Add background color */}
//             <div className="checkbox-container flex items-center mt-4">
//               <input
//                 type="checkbox"
//                 id="terms"
//                 name="terms"
//                 className="mr-2"
//                 checked={isChecked}
//                 onChange={handleCheckboxChange}
//               />
//               <label htmlFor="terms" className="text-sm">
//                 I have read and understood the terms stipulated in the
//                 merchant agreement. I consent to the terms outlined.
//               </label>
//             </div>
//             <button
//               onClick={handleSendOtp}
//               disabled={!isChecked} // Disable button if checkbox is not checked
//               className={`text-white text-center rounded h-10 w-44 mt-5 ${isChecked
//                 ? "bg-green-600 cursor-pointer"
//                 : "bg-gray-400 cursor-not-allowed"
//                 }`}
//             >
//               Proceed to Sign
//             </button>
//           </div>
//         </div>
//       </main>
//       {/* OTP Modal code here */}
//       {showOtpModal && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-sm">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-xl font-semibold">
//                 Enter verification code
//               </h2>
//               <button
//                 onClick={() => setShowOtpModal(false)}
//                 className="text-gray-400 hover:text-gray-600"
//               >
//                 <FontAwesomeIcon icon={faTimes} className="text-xl" />
//               </button>
//             </div>
//             <p className="text-center text-gray-600 mb-4">
//               6 digit OTP has been sent to your registered mobile number
//             </p>
//             <div className="flex justify-center space-x-2 mb-4">
//               {otp.map((digit, index) => (
//                 <input
//                   key={index}
//                   type="text"
//                   maxLength="1"
//                   className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
//                   value={digit}
//                   onChange={(e) => handleOtpChange(index, e.target.value)}
//                   onKeyDown={(e) => handleKeyDown(index, e)}
//                   ref={(el) => inputRefs.current[index] = el}
//                 />
//               ))}
//             </div>
//             <div className="flex justify-center mt-4">
//               <button
//                 onClick={handleSignClick}
//                 className={`text-white text-center rounded h-10 w-36 ${otp.join('').length === 6 ? 'bg-green-600 cursor-pointer' : 'bg-gray-400 cursor-not-allowed'}`}
//                 disabled={otp.join('').length !== 6}
//               >
//                 Sign
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//       {showLogoutModal && (
//         <LogoutModel
//           onClickLogout={confirmLogout}
//           onClickCancel={() => setShowLogoutModal(false)}
//         />
//       )}
//     </div>
//   );
// }

// export default SignDocument;





import React, { useReducer, useRef } from 'react';
import Logo from '../images/logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { logoutService } from '../services/LogoutService';
import LogoutModel from "./LogoutModel";
import { sendOtp, signMerchantAgreementDocument } from '../services/Api';
import { handleError } from '../services/errorHandler';

const initialState = {
  showLogoutModal: false,
  showOtpModal: false,
  isChecked: false,
  otp: Array(6).fill(''),
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SHOW_LOGOUT_MODAL':
      return { ...state, showLogoutModal: action.payload };
    case 'SET_SHOW_OTP_MODAL':
      return { ...state, showOtpModal: action.payload };
    case 'SET_IS_CHECKED':
      return { ...state, isChecked: !state.isChecked };
    case 'SET_OTP':
      return { ...state, otp: action.payload };
    default:
      return state;
  }
}

function SignDocument() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const inputRefs = useRef(new Array(6).fill(null));

  const restaurantId = localStorage.getItem("restaurant_Id");

  const confirmLogout = () => {
    dispatch({ type: 'SET_SHOW_LOGOUT_MODAL', payload: false });
    logoutService('Session');
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      await sendOtp(restaurantId);
      dispatch({ type: 'SET_SHOW_OTP_MODAL', payload: true });
    } catch (error) {
      handleError(error);
    }
  };

  // Handle OTP input change
  const handleOtpChange = (index, value) => {
    if (value.length === 1 && /^\d*$/.test(value)) {
      const newOtp = [...state.otp];
      newOtp[index] = value;
      dispatch({ type: 'SET_OTP', payload: newOtp });

      if (index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    } else if (value === '') {
      const newOtp = [...state.otp];
      newOtp[index] = '';
      dispatch({ type: 'SET_OTP', payload: newOtp });
      inputRefs.current[index]?.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      const newOtp = [...state.otp];
      newOtp[index] = '';
      dispatch({ type: 'SET_OTP', payload: newOtp });

      if (index > 0 && state.otp[index] === '') {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handleSignClick = async () => {
    dispatch({ type: 'SET_SHOW_OTP_MODAL', payload: false });

    const metadataId = localStorage.getItem("AgreementMetaDataId");
    const ownerEmail = localStorage.getItem("ownerEmail");
    const ownerMobile = localStorage.getItem("ownerMobile");
    const restaurantId = localStorage.getItem("restaurant_Id");

    if (!metadataId || !ownerEmail || !ownerMobile || !restaurantId) {
      console.error("Missing required information to sign document.");
      return;
    }

    try {
      const response = await signMerchantAgreementDocument(metadataId, ownerEmail, ownerMobile, restaurantId);
      navigate('/complete-sign');
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <header className="w-full bg-white shadow-md flex items-center justify-between p-3">
        <div className="flex items-center">
          <img src={Logo} alt="Logo" className="h-12" />
        </div>
        <div className="flex items-center">
          <button
            className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
            onClick={() => dispatch({ type: 'SET_SHOW_LOGOUT_MODAL', payload: true })}
          >
            Logout
          </button>
        </div>
      </header>
      <main className="flex-grow flex flex-col items-center mt-5 w-full">
        <div className="shadow-lg rounded-lg overflow-hidden flex flex-col items-center">
          <div className="w-full">
            <iframe
              src="https://s3.ap-south-1.amazonaws.com/merchant-assets.thegenie.in/restaurant/MerchantAgreements/dummyRestaurant/The+Genie+Merchant+Onboarding+Documents.pdf"
              title="Document"
              className="w-full h-screen border-0"
            ></iframe>
          </div>

          <div className="w-full p-6 flex flex-col items-center bg-gray-100">
            <div className="checkbox-container flex items-center mt-4">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                className="mr-2"
                checked={state.isChecked}
                onChange={() => dispatch({ type: 'SET_IS_CHECKED' })}
              />
              <label htmlFor="terms" className="text-sm">
                I have read and understood the terms stipulated in the merchant agreement. I consent to the terms outlined.
              </label>
            </div>
            <button
              onClick={handleSendOtp}
              disabled={!state.isChecked}
              className={`text-white text-center rounded h-10 w-44 mt-5 ${state.isChecked ? "bg-green-600 cursor-pointer" : "bg-gray-400 cursor-not-allowed"}`}
            >
              Proceed to Sign
            </button>
          </div>
        </div>
      </main>

      {/* OTP Modal */}
      {state.showOtpModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-sm">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Enter verification code</h2>
              <button onClick={() => dispatch({ type: 'SET_SHOW_OTP_MODAL', payload: false })} className="text-gray-400 hover:text-gray-600">
                <FontAwesomeIcon icon={faTimes} className="text-xl" />
              </button>
            </div>
            <p className="text-center text-gray-600 mb-4">
              6 digit OTP has been sent to your registered mobile number
            </p>
            <div className="flex justify-center space-x-2 mb-4">
              {state.otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  ref={(el) => inputRefs.current[index] = el}
                />
              ))}
            </div>
            <div className="flex justify-center mt-4">
              <button
                onClick={handleSignClick}
                className={`text-white text-center rounded h-10 w-36 ${state.otp.join('').length === 6 ? 'bg-green-600 cursor-pointer' : 'bg-gray-400 cursor-not-allowed'}`}
                disabled={state.otp.join('').length !== 6}
              >
                Sign
              </button>
            </div>
          </div>
        </div>
      )}

      {state.showLogoutModal && (
        <LogoutModel
          onClickLogout={confirmLogout}
          onClickCancel={() => dispatch({ type: 'SET_SHOW_LOGOUT_MODAL', payload: false })}
        />
      )}
    </div>
  );
}

export default SignDocument;
