import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { addUserReferralForOnboarding } from "../services/Api";

const ReferrerModal = ({ toggleReferModal, onClickSuccess, initialMobileNumber }) => {
  const [state, setState] = useState({
    mobileNumber: initialMobileNumber?.replace(/^\+91/, "") || "",
    restaurantId: localStorage.getItem("restaurant_Id"), // Assuming the restaurantId is fixed
    error: "",
    isSubmitting: false,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      mobileNumber: initialMobileNumber?.replace(/^\+91/, "") || "", // Ensure mobile number is updated on open
    }));
  }, [initialMobileNumber]);

  // Mobile number validation logic
  const validateMobileNumber = (number) => {
    const mobileNumberRegex = /^[6-9]\d{9}$/;
    if (!number) return "Mobile number cannot be empty.";
    if (!mobileNumberRegex.test(number)) return "Invalid mobile number. Please enter a valid 10-digit number.";
    return ""; // No errors
  };

  // Handle input changes
  const handleMobileNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Allow only digits
    setState((prevState) => ({ ...prevState, mobileNumber: input }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    const validationError = validateMobileNumber(state.mobileNumber);
    if (validationError) {
      setState((prevState) => ({ ...prevState, error: validationError }));
    } else {
      setState((prevState) => ({ ...prevState, error: "", isSubmitting: true }));

      try {
        const response = await addUserReferralForOnboarding(state.restaurantId, `+91${state.mobileNumber}`);
        if (response.status === 'created' || response.status === 'updated') {
          // Pass the mobile number to the parent component and close the modal
          onClickSuccess(state.mobileNumber);
          localStorage.setItem("referrerMobileNumber", state.mobileNumber);
          toggleReferModal(); // Close the modal after success
        }
      } catch (error) {
        console.error("Error adding referral:", error);
      } finally {
        setState((prevState) => ({ ...prevState, isSubmitting: false }));
        toggleReferModal(); // Close the modal after failure or success
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-screen">
      <div className="bg-white rounded-lg shadow-lg p-5 w-11/12 max-w-md mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Mobile Number of Referrer</h2>
          <button onClick={toggleReferModal} className="text-gray-400 hover:text-gray-600">
            <FontAwesomeIcon icon={faTimes} className="text-xl" />
          </button>
        </div>
        <hr className="-mx-3 border-gray-300 mb-4" />

        <div className="mb-4">
          <div className="relative flex items-center border border-gray-300 outline-none shadow-sm mb-2 sm:text-sm rounded p-2">
            <div className="flex items-center">
              <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png"
                alt="India Flag" className="w-6 h-4 mr-2" />
              <span className="text-gray-700">+91</span>
            </div>
            <input type="tel" placeholder="Mobile number*" value={state.mobileNumber}
              onChange={handleMobileNumberChange} minLength="10" maxLength="10"
              className={`ml-2 outline-none flex-1 border-l border-gray-300 pl-2 ${state.error ? "border-red-500" : ""}`} />
          </div>
          {state.error && <p className="text-red-500 text-center text-sm">{state.error}</p>}
        </div>

        <hr className="-mx-3 border-gray-300 mb-4" />
        <div className="flex justify-center">
          <button
            onClick={handleSubmit}
            className="okay-btn text-white py-2 px-6 rounded-lg"
            disabled={state.isSubmitting} // Disable button while submitting
          >
            {state.isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferrerModal;







// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import { addUserReferralForOnboarding } from "../services/Api";

// const ReferrerModal = ({ toggleReferModal, onClickSuccess, initialMobileNumber }) => {
//   const [state, setState] = useState({
//     mobileNumber: initialMobileNumber?.replace(/^\+91/, "") || "", // Load the initial number if available
//     restaurantId: localStorage.getItem("restaurant_Id"),
//     error: "",
//     isSubmitting: false,
//   });

//   useEffect(() => {
//     setState((prevState) => ({
//       ...prevState,
//       mobileNumber: initialMobileNumber?.replace(/^\+91/, "") || "", // Ensure mobile number is updated on open
//     }));
//   }, [initialMobileNumber]);

//   const validateMobileNumber = (number) => {
//     const mobileNumberRegex = /^[6-9]\d{9}$/;
//     if (!number) return "Mobile number cannot be empty.";
//     if (!mobileNumberRegex.test(number)) return "Invalid mobile number. Please enter a valid 10-digit number.";
//     return "";
//   };

//   const handleMobileNumberChange = (e) => {
//     const input = e.target.value.replace(/\D/g, ""); // Allow only digits
//     setState((prevState) => ({ ...prevState, mobileNumber: input }));
//   };

//   const handleSubmit = async () => {
//     const validationError = validateMobileNumber(state.mobileNumber);
//     if (validationError) {
//       setState((prevState) => ({ ...prevState, error: validationError }));
//     } else {
//       setState((prevState) => ({ ...prevState, error: "", isSubmitting: true }));

//       try {
//         const response = await addUserReferralForOnboarding(state.restaurantId, `+91${state.mobileNumber}`);
//         if (response.status === 'created' || response.status === 'updated') {
//           onClickSuccess(state.mobileNumber); // Pass updated number to parent
//           localStorage.setItem("referrerMobileNumber", state.mobileNumber); // Save to localStorage
//           toggleReferModal(); // Close modal after success
//         }
//       } catch (error) {
//         console.error("Error adding referral:", error);
//       } finally {
//         setState((prevState) => ({ ...prevState, isSubmitting: false }));
//       }
//     }
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-screen">
//       <div className="bg-white rounded-lg shadow-lg p-5 w-11/12 max-w-md mx-auto">
//         <div className="flex justify-between items-center mb-4">
//           <h2 className="text-xl font-semibold">Mobile Number of Referrer</h2>
//           <button onClick={toggleReferModal} className="text-gray-400 hover:text-gray-600">
//             <FontAwesomeIcon icon={faTimes} className="text-xl" />
//           </button>
//         </div>
//         <hr className="-mx-3 border-gray-300 mb-4" />

//         <div className="mb-4">
//           <div className="relative flex items-center border border-gray-300 outline-none shadow-sm mb-2 sm:text-sm rounded p-2">
//             <div className="flex items-center">
//               <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png"
//                 alt="India Flag" className="w-6 h-4 mr-2" />
//               <span className="text-gray-700">+91</span>
//             </div>
//             <input type="tel" placeholder="Mobile number*" value={state.mobileNumber}
//               onChange={handleMobileNumberChange} minLength="10" maxLength="10"
//               className={`ml-2 outline-none flex-1 border-l border-gray-300 pl-2 ${state.error ? "border-red-500" : ""}`} />
//           </div>
//           {state.error && <p className="text-red-500 text-center text-sm">{state.error}</p>}
//         </div>

//         <hr className="-mx-3 border-gray-300 mb-4" />
//         <div className="flex justify-center">
//           <button
//             onClick={handleSubmit}
//             className="okay-btn text-white py-2 px-6 rounded-lg"
//             disabled={state.isSubmitting}
//           >
//             {state.isSubmitting ? "Submitting..." : "Submit"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReferrerModal;
