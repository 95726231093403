import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { verifyOtp, sendOtp } from "../services/AuthApi";
import { handleError } from "../services/errorHandler";
import { useNavigate } from "react-router-dom";
import { updateApiBaseUrl } from "../services/Api";

const Otp = ({ onClose }) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const inputRefs = useRef(new Array(6).fill(null));
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  // Function to get phone number from localStorage
  const getPhoneNumber = () => {
    const phoneNumber = localStorage.getItem("phoneNumber");
    if (!phoneNumber) {
      alert("Phone number not found. Please retry.");
      return null;
    }
    return phoneNumber;
  };

  // Handle OTP input change
  const handleOtpChange = (index, value) => {
    if (value.length === 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputRefs.current[index + 1]?.focus(); // Focus on the next input field
      }
    } else if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = ""; // Clear the current input
      setOtp(newOtp);
      inputRefs.current[index]?.focus(); // Stay on the same field
    }
  };

  // Handle backspace key press in OTP fields
  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = ""; // Clear current digit only

      // Update the OTP state
      setOtp(newOtp);

      // Move focus to the previous input, but don't clear it
      if (index > 0 && otp[index] === "") {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  // OTP verification process
  const handleVerifyOtp = async () => {
    const otpString = otp.join("");
    if (otpString.length !== 6) {
      alert("Please enter the full 6-digit OTP");
      return;
    }

    const phoneNumber = getPhoneNumber();
    if (!phoneNumber) return;

    const uniqueAttemptId = sessionStorage.getItem("uniqueAttemptId");

    try {
      // if(otpString === localStorage.getItem("otp")) {
      const response = await verifyOtp(phoneNumber, otpString, uniqueAttemptId);
      if (response) {
        const {
          accessToken,
          refreshToken,
          accessTokenExpiry,
          refreshTokenExpiry,
          restaurants,
          endpoint,
        } = response || {};

        if (!endpoint) {
          alert("Failed to set the API endpoint. Please try again.");
          return;
        }

        // Store tokens and other info in localStorage
        localStorage.setItem("endpoint", endpoint);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("accessTokenExpiry", accessTokenExpiry);
        localStorage.setItem("refreshTokenExpiry", refreshTokenExpiry);
        localStorage.setItem("restaurant_Id", restaurants);

        // Updates the API base URL
        updateApiBaseUrl();

        // Closes OTP modal before navigating
        onClose(false);

        // Navigates to the next step
        navigate("/register");
      } else {
        alert("OTP verification failed. Please try again.");
      }
      // } else {
      //     setErrorMessage("Wrong OTP...")
      // }
    } catch (error) {
      handleError(error);
    }
  };

  // Handle resend OTP action
  const handleResendOtp = async () => {
    const phoneNumber = getPhoneNumber();
    if (!phoneNumber) return;

    try {
      await sendOtp(phoneNumber);
      // Reset OTP input fields
      setOtp(Array(6).fill(""));
      inputRefs.current[0]?.focus();
    } catch (error) {
      handleError(error);
    }
  };

  // Styles reused
  const buttonClass =
    "bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded";
  const inputClass =
    "w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500";

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
      <div className="bg-white rounded-lg shadow-lg w-96 p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">OTP Verification</h2>
          <button
            onClick={() => onClose(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="text-center">
          <p className="text-gray-500">Check text messages for your OTP</p>
          <div className="flex justify-center mt-4 space-x-2">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className={inputClass}
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>
          {errorMessage && (
            <p className="text-red-500 font-bold mt-2">{errorMessage}</p>
          )}
          <div className="mt-2">
            <button
              className={`${buttonClass} ${
                otp.join("").length === 6 ? "" : "opacity-50 cursor-not-allowed"
              }`}
              onClick={handleVerifyOtp}
              disabled={otp.join("").length !== 6}
            >
              Verify OTP
            </button>
          </div>
          <div className="mt-2 text-gray-500">
            Not received OTP?{" "}
            <button
              className="text-red-500 hover:underline"
              onClick={handleResendOtp}
            >
              Resend Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;

// Don't Remove this code This is the Updated One but still needs to confirm

// import React, { useState, useRef, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { verifyOtp, sendOtp } from '../services/AuthApi';
// import { handleError } from '../services/errorHandler';
// import { useNavigate } from 'react-router-dom';
// import { updateApiBaseUrl } from '../services/Api';

// const Otp = ({ onClose }) => {
//     const [otp, setOtp] = useState(Array(6).fill(''));
//     const [isResendEnabled, setIsResendEnabled] = useState(false);
//     const [otpExpired, setOtpExpired] = useState(false);
//     const [retriesLeft, setRetriesLeft] = useState(0);
//     const inputRefs = useRef(new Array(6).fill(null));
//     const navigate = useNavigate();
//     const [errorMessage, setErrorMessage] = useState("");

//     // Handle OTP input change
//     const handleOtpChange = (index, value) => {
//         if (value.length === 1 && /^\d*$/.test(value)) {
//             const newOtp = [...otp];
//             newOtp[index] = value;
//             setOtp(newOtp);

//             if (index < 5) {
//                 inputRefs.current[index + 1]?.focus();  // Focus on the next input field
//             }
//         } else if (value === '') {
//             const newOtp = [...otp];
//             newOtp[index] = '';  // Clear the current input
//             setOtp(newOtp);
//             inputRefs.current[index]?.focus();  // Stay on the same field
//         }
//     };

//     // Handle backspace key press in OTP fields
//     const handleKeyDown = (index, event) => {
//         if (event.key === 'Backspace') {
//             const newOtp = [...otp];
//             newOtp[index] = '';  // Clear current digit only

//             // Update the OTP state
//             setOtp(newOtp);

//             // Move focus to the previous input, but don't clear it
//             if (index > 0 && otp[index] === '') {
//                 inputRefs.current[index - 1]?.focus();
//             }
//         }
//     };

//     useEffect(() => {
//         const otpDetails = JSON.parse(localStorage.getItem("otpDetails"));
//         if (otpDetails) {
//             setRetriesLeft(otpDetails.retriesLeft);

//             const timeUntilResend = otpDetails.otpRetryWindow - Date.now();
//             const timeUntilExpiry = otpDetails.otpExpiry - Date.now();

//             // Set timer to enable resend button after 7 minutes
//             if (timeUntilResend > 0) {
//                 setTimeout(() => {
//                     setIsResendEnabled(true);
//                 }, timeUntilResend);
//             } else {
//                 setIsResendEnabled(true);
//             }

//             // Set timer to mark OTP as expired after 5 minutes
//             if (timeUntilExpiry > 0) {
//                 setTimeout(() => {
//                     setOtpExpired(true);
//                 }, timeUntilExpiry);
//             } else {
//                 setOtpExpired(true);
//             }
//         }
//     }, []);

//     const handleVerifyOtp = async () => {
//         const otpString = otp.join('');
//         if (otpString.length !== 6) {
//             alert('Please enter the full 6-digit OTP');
//             return;
//         }

//         if (otpExpired) {
//             alert('OTP expired. Please click resend to get a new OTP.');
//             return;
//         }

//         const phoneNumber = localStorage.getItem("phoneNumber");
//         if (!phoneNumber) return;

//         try {
//             if (otpString === localStorage.getItem("otp")) {
//                 const response = await verifyOtp(phoneNumber, otpString);
//                 const { accessToken, refreshToken, accessTokenExpiry, refreshTokenExpiry, restaurants, endpoint } = response || {};

//                 // Store tokens and navigate to the next step
//                 localStorage.setItem("endpoint", endpoint);
//                 localStorage.setItem("accessToken", accessToken);
//                 localStorage.setItem("refreshToken", refreshToken);
//                 localStorage.setItem("accessTokenExpiry", accessTokenExpiry);
//                 localStorage.setItem("refreshTokenExpiry", refreshTokenExpiry);
//                 localStorage.setItem("restaurant_Id", restaurants);

//                 updateApiBaseUrl();
//                 onClose(false);
//                 navigate('/register');
//             } else {
//                 setErrorMessage("Wrong OTP...")
//             }
//         } catch (error) {
//             handleError(error);
//         }
//     };

//     const handleResendOtp = async () => {
//         if (retriesLeft <= 0) {
//             alert('You have exceeded the number of resend attempts.');
//             return;
//         }

//         const phoneNumber = localStorage.getItem("phoneNumber");
//         if (!phoneNumber) return;

//         try {
//             const response = await sendOtp(phoneNumber);
//             const { otp, retriesLeft, otpExpiry, otpRetryWindow } = response.otp;

//             // Update OTP details in localStorage
//             localStorage.setItem("otpDetails", JSON.stringify({
//                 otp: otp,
//                 otpExpiry: Date.now() + otpExpiry * 1000,
//                 retriesLeft: retriesLeft - 1,
//                 otpRetryWindow: Date.now() + otpRetryWindow * 1000
//             }));

//             // Reset OTP input fields and disable resend button for 7 minutes
//             setOtp(Array(6).fill(''));
//             inputRefs.current[0]?.focus();
//             setRetriesLeft(retriesLeft - 1);
//             setIsResendEnabled(false);

//             setTimeout(() => {
//                 setIsResendEnabled(true);
//             }, otpRetryWindow * 1000);

//             // Reset OTP expiry logic
//             setTimeout(() => {
//                 setOtpExpired(true);
//             }, otpExpiry * 1000);
//         } catch (error) {
//             handleError(error);
//         }
//     };

//     return (
//         <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
//             <div className="bg-white rounded-lg shadow-lg w-96 p-6">
//                 <div className="flex justify-between items-center mb-4">
//                     <h2 className="text-xl font-semibold">OTP Verification</h2>
//                     <button onClick={() => onClose(false)} className="text-gray-500 hover:text-gray-700">
//                         <FontAwesomeIcon icon={faTimes} />
//                     </button>
//                 </div>
//                 <div className="text-center">
//                     <p className="text-gray-500">Check text messages for your OTP</p>
//                     <div className="flex justify-center mt-4 space-x-2">
//                         {otp.map((digit, index) => (
//                             <input
//                                 key={index}
//                                 type="text"
//                                 maxLength="1"
//                                 className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
//                                 value={digit}
//                                 onChange={(e) => handleOtpChange(index, e.target.value)}
//                                 onKeyDown={(e) => handleKeyDown(index, e)}
//                                 ref={(el) => inputRefs.current[index] = el}
//                             />
//                         ))}
//                     </div>
//                     {otpExpired && <p className="text-red-500 text-sm font-bold mt-3">OTP expired. Please resend to get a new one.</p>}
//                     {errorMessage && <p className='text-red-500 font-bold mt-2'>{errorMessage}</p>}
//                     <div className="mt-2">
//                         <button
//                             className={`bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded ${otp.join('').length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
//                             onClick={handleVerifyOtp}
//                             disabled={otp.join('').length !== 6}
//                         >
//                             Verify OTP
//                         </button>
//                     </div>
//                     <div className="mt-2 text-gray-500">
//                         Not received OTP?{' '}
//                         <button
//                             className={`text-red-500 font-bold hover:underline ${isResendEnabled ? '' : 'opacity-50 cursor-not-allowed'}`}
//                             onClick={handleResendOtp}
//                             disabled={!isResendEnabled}
//                         >
//                             Resend Now
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Otp;
