import React, { useState, useEffect, useRef } from "react";
import "./Registration.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { logoutService } from "../services/LogoutService";
import LogoutModel from "./LogoutModel";
import { APP_NAME } from "../constants";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";
import { checkOnboardingStatus, readRestaurantInfo, updateRestaurantInfo } from "../services/Api";
import { sendOtpToOwner, verifyOwnerOtp } from "../services/Api";
import { handleError } from "../services/errorHandler";
import { fetchCurrentUTCTime } from "../services/AuthApi";
import { FaCrosshairs } from "react-icons/fa6";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const Registration = () => {
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const [state, setState] = useState({
    restaurantName: "",
    restaurantNameError: '', // Error message for restaurant name
    inputsDisabled: false,
    ownerName: "",
    ownerNameError: "",
    ownerEmailError: "",
    restaurantPhoneNumber: "",
    isPhoneFetched: false,
    ownerEmail: "",
    shopNo: "",
    floor: "",
    area: "",
    isSameAsOwner: false,
    showModal: false,
    showReferModal: false,
    showOtpModal: false,
    isOtpValid: false,
    otp: Array(6).fill(''),
    timer: 30,
    isOtpVerified: false,
    otpError: "",
    showLogoutModal: false,
    isFormValid: false,
    restaurantId: localStorage.getItem("restaurant_Id"),
    latitude: "",
    longitude: "",
    cityState: "",
    zipCode: ""
  });

  // Validate fields: common validation function
  const validateFields = () => {
    let errors = {};

    // Restaurant Name validation: no numbers or special characters
    if (!state.restaurantName.trim()) {
      errors.restaurantNameError = "Restaurant name cannot be empty.";
    } else if (!/^[A-Za-z\s]*$/.test(state.restaurantName)) {
      errors.restaurantNameError = "Restaurant name can only contain letters and spaces.";
    }

    // Owner Name validation: no numbers or special characters
    if (!state.ownerName.trim()) {
      errors.ownerNameError = "Owner name cannot be empty.";
    } else if (!/^[A-Za-z\s]*$/.test(state.ownerName)) {
      errors.ownerNameError = "Owner name can only contain letters and spaces.";
    }

    // Email validation
    if (!state.ownerEmail.trim()) {
      errors.ownerEmailError = "Owner email cannot be empty.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(state.ownerEmail)) {
      errors.ownerEmailError = "Please enter a valid email address.";
    }

    // Area validation
    if (!state.area.trim()) {
      errors.areaError = "Area cannot be empty.";
    } else if (/[^A-Za-z0-9\s]/.test(state.area)) {
      errors.areaError = "Area cannot contain special characters.";
    }

    // City/State validation
    if (!state.cityState.trim()) {
      errors.cityStateError = "City and state cannot be empty.";
    } else if (/[^A-Za-z\s,]/.test(state.cityState)) {
      errors.cityStateError = "City and state can only contain letters and commas.";
    }

    // Zip code validation
    if (!state.zipCode.trim()) {
      errors.zipCodeError = "Zip code cannot be empty.";
    } else if (!/^\d{6}$/.test(state.zipCode)) {
      errors.zipCodeError = "Invalid zip code format. Zip code must be 6 digits.";
    }

    // Update state with errors
    setState(prevState => ({
      ...prevState,
      ...errors,
    }));

    // Check if form is valid
    return Object.keys(errors).length === 0;
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setState(prevState => ({
        ...prevState,
        restaurantPhoneNumber: value,
      }));
    }
  };

  useEffect(() => {
    const syncTime = async () => {
      try {
        const utcTime = await fetchCurrentUTCTime();  // Fetch the UTC time from API
        const utcTimestamp = new Date(utcTime);  // Convert fetched time to Date object
        console.log("UTC Time Stamp", utcTimestamp);
        localStorage.setItem("currentTime", utcTimestamp.getTime().toString()); // Store timestamp in milliseconds
      } catch (error) {
        console.error("Failed to sync time:", error);
      }
    };

    syncTime(); // Sync the time initially

    const interval = setInterval(() => {
      const storedTime = parseInt(localStorage.getItem("currentTime"), 10); // Parse the stored timestamp as an integer
      if (storedTime) {
        const updatedTime = storedTime + 1000; // Increment by 1000 milliseconds (1 second)
        localStorage.setItem("currentTime", updatedTime.toString());
        console.log("Updated Time:", new Date(updatedTime)); // Log the updated time as a Date object
      }
    }, 1000);

    // Clear the interval when component unmounts
    return () => clearInterval(interval);
  }, []);

  // Next Button function
  const handleNextClick = async () => {
    if (validateFields()) {
      try {
        localStorage.setItem("restaurantName", state.restaurantName)
        localStorage.setItem("ownerName", state.ownerName)
        // Save restaurant info
        const response = await updateRestaurantInfo(restaurantInfoData, state.restaurantId);
        // Stores the data in localStorage
        localStorage.setItem("restaurantInfoData", JSON.stringify(restaurantInfoData));
        localStorage.setItem("restaurantInfoComplete", "true"); // A flag to indicate that the form has been saved

        // Disable input fields after saving
        setState(prevState => ({
          ...prevState,
          inputsDisabled: true,
        }));

        // Navigate to the "menu-operational" page
        navigate("/menu-operational");
      } catch (error) {
        console.error("Error updating restaurant info:", error);
        throw error;
      }
    } else {
      alert("Please fill in all the required fields correctly.");
    }
  };

  // Logout Modal
  const confirmLogout = () => {
    setState((prevState) => ({
      ...prevState,
      showLogoutModal: false,
    }));
    logoutService("Session");
  };
  // For displaying modal - Mandatory modal
  const toggleModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
    }));
  };

  // For displaying referral modal
  const toggleReferModal = () => {
    setState((prevState) => ({
      ...prevState,
      showReferModal: !prevState.showReferModal
    }));
  };

  const handleOtpChange = (index, value) => {
    if (value.length === 1 && /^\d*$/.test(value)) {
      const newOtp = [...state.otp];
      newOtp[index] = value;
      setState(prevState => ({
        ...prevState,
        otp: newOtp,  // Updates the OTP in the state
        isOtpValid: newOtp.join('').length === 6 // Check if OTP is fully entered
      }));

      if (index < 5) {
        inputRefs.current[index + 1]?.focus();  // Focus on the next input field
      }
    } else if (value === '') {
      const newOtp = [...state.otp];
      newOtp[index] = '';  // Clear the current input
      setState(prevState => ({
        ...prevState,
        otp: newOtp,  // Update the OTP in the state
        isOtpValid: false // Set OTP valid to false if any field is cleared
      }));
      inputRefs.current[index]?.focus();  // Stay on the same field
    }
  };

  // Handle backspace key press in OTP fields
  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      const newOtp = [...state.otp];
      newOtp[index] = '';  // Clear current digit only
      setState(prevState => ({
        ...prevState,
        otp: newOtp,  // Update the OTP in the state
      }));
      // Move focus to the previous input, but don't clear it
      if (index > 0 && state.otp[index] === '') {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  // For sending otp
  const phone = `+91${state.restaurantPhoneNumber}`;
  localStorage.setItem("restaurantPhoneNumber", phone);

  // Verify Button function
  const handleVerifyButtonClick = async (e) => {
    e.preventDefault();

    if (state.inputsDisabled) {
      return; // Don't allow verification if the inputs are disabled
    }

    try {
      await sendOtpToOwner(phone, state.restaurantId);
      setState((prevState) => ({
        ...prevState,
        showOtpModal: true,
      }))
    } catch (error) {
      handleError(error);
    }
  };

  // For resending otp
  const handleResendOtp = async () => {

    if (state.inputsDisabled) {
      return; // Don't allow verification if the inputs are disabled
    }

    try {
      await sendOtpToOwner(phone, state.restaurantId);
      setState((prevState) => ({
        ...prevState,
        otp: Array(6).fill(""),
      }));
      inputRefs.current[0]?.focus();
    } catch (error) {
      handleError(error);
    }
  };

  // For verifing otp
  const handleVerifyOtp = async (e) => {

    if (state.inputsDisabled) {
      return; // Don't allow verification if the inputs are disabled
    }

    const finalOtp = state.otp.join("")
    e.preventDefault();
    try {
      await verifyOwnerOtp(phone, finalOtp);
      setState((prevState) => ({
        ...prevState,
        showOtpModal: false,
        isOtpVerified: true,
        inputsDisabled: true, // Disable input fields after OTP verification
      }));
    } catch (error) {
      handleError(error);

      // Don't delete this
      // Set OTP error message on failure
      // setState((prevState) => ({
      //   ...prevState,
      //   otpError: "OTP is invalid", // Set the OTP error message
      // }));
    }
  };

  // Timer management useEffect
  useEffect(() => {
    let timerInterval;
    if (state.showOtpModal && state.timer > 0) {
      timerInterval = setInterval(() => {
        setState((prevState) => ({
          ...prevState,
          timer: prevState.timer - 1,
        }));
      }, 1000);
    } else if (state.timer === 0) {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);
  }, [state.showOtpModal, state.timer]);

  // Validate Form
  useEffect(() => {
    const isValid =
      state.restaurantName.trim() !== "" &&
      state.ownerName.trim() !== "" &&
      state.ownerEmail.trim() !== "" &&
      state.restaurantPhoneNumber.length === 10 &&
      state.area.trim() !== "" &&  // Area
      state.cityState.trim() !== "" &&  // City and State
      state.latitude !== "" &&  // Latitude
      state.longitude !== "" &&  // Longitude
      state.shopNo !== "" &&
      state.floor !== "" &&
      state.zipCode !== ""
    setState((prevState) => ({
      ...prevState,
      isFormValid: isValid,
    }));
  }, [state.restaurantName, state.ownerName, state.ownerEmail, state.restaurantPhoneNumber, state.area, state.cityState, state.latitude, state.longitude, state.shopNo, state.floor, state.zipCode]);

  // Redirect if not logged in
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  const restaurantInfoData = {
    restaurantName: state.restaurantName,
    ownerFullName: state.ownerName,
    restaurantPhoneNumber: state.restaurantPhoneNumber,
    ownerEmailId: state.ownerEmail,
    restaurantPrimaryContact: phone,
    latitude: state.latitude,
    longitude: state.longitude,
    shopNo: state.shopNo,
    floor: state.floor,
    area: state.area,
    cityState: state.cityState,
  };

  useEffect(() => {
    const savedMenuData = localStorage.getItem("menuOperationData");
    if (savedMenuData) {
      const parsedMenuData = JSON.parse(savedMenuData);
      localStorage.setItem("parsedMenuData", JSON.stringify(parsedMenuData))
    }
  }, []);

  useEffect(() => {
    const savedResDocsData = localStorage.getItem("restaurantDocumentsData");
    if (savedResDocsData) {
      const parsedData = JSON.parse(savedResDocsData);
      localStorage.setItem("parsedResDocsData", JSON.stringify(parsedData))
    }
  }, []);

  useEffect(() => {

    const fetchRestaurantInfo = async () => {
      try {
        const response = await readRestaurantInfo(state.restaurantId);

        if (response) {
          const {
            restaurantName,
            ownerFullName,
            restaurantPrimaryContact,
            ownerEmailId,
            latitude,
            longitude,
            shopNo,
            floor,
            area,
            cityState,
          } = response;

          // Remove the '+91' prefix if it exists to display only the actual number
          const cleanPhoneNumber = restaurantPrimaryContact?.startsWith('+91')
            ? restaurantPrimaryContact.slice(3)
            : restaurantPrimaryContact;

          // Setting state with response values
          setState((prevState) => ({
            ...prevState,
            restaurantName: restaurantName || prevState.restaurantName,
            ownerName: ownerFullName || prevState.ownerName,  // Add a fallback
            restaurantPhoneNumber: cleanPhoneNumber || prevState.restaurantPhoneNumber, // Updated to only show the number
            ownerEmail: ownerEmailId || prevState.ownerEmail,
            latitude: latitude || prevState.latitude,
            longitude: longitude || prevState.longitude,
            shopNo: shopNo || prevState.shopNo,
            floor: floor || prevState.floor,
            area: area || prevState.area,
            cityState: cityState || prevState.cityState,
            isPhoneFetched: !!cleanPhoneNumber, // Disable the phone field if fetched from API
            isOtpVerified: cleanPhoneNumber?.length === 10 // If phone number is valid (10 digits), mark OTP as verified
          }));
        }
      } catch (error) {
        console.error("Error fetching restaurant info:", error);
      }
    };

    // Only run on mount
    fetchRestaurantInfo();
  }, []);

  // For saving Restaurant Info Data
  const handleSaveDraft = () => {

    if (state.inputsDisabled) {
      return; // Don't allow verification if the inputs are disabled
    }

    // Stores the data in localStorage
    localStorage.setItem("restaurantInfoData", JSON.stringify(restaurantInfoData));
  };

  const [showMapModal, setShowMapModal] = useState(false);
  const [mapCenter, setMapCenter] = useState([12.9716, 77.5946]); // Default center (Bangalore)
  const [markerPosition, setMarkerPosition] = useState([12.9716, 77.5946]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  // Function to open the map modal
  const handleShowMap = () => {
    setShowMapModal(true);
  };

  // Function to close the map modal
  const handleCloseMap = () => {
    setShowMapModal(false);
  };

  // Fetch city and state using Nominatim reverse geocoding
  const fetchCityAndState = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
      );
      const address = response.data.address;

      const cityState = `${address.city || address.town || address.village}, ${address.state}`;
      const zipCode = `${address.postcode}`
      setState((prevState) => ({
        ...prevState,
        cityState,
        latitude: lat,
        longitude: lng,
        zipCode
      }));
    } catch (error) {
      console.error("Error fetching city and state:", error);
    }
  };

  // Function to search for places using Nominatim API
  const fetchSearchResults = async (input) => {
    if (input.length > 2) {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?q=${input}&format=json&addressdetails=1&limit=5&countrycodes=IN`
        );
        const filteredResults = response.data.filter(
          (place) =>
            place.address.state === "Tamil Nadu" || place.address.state === "Karnataka"
        );
        setSearchSuggestions(filteredResults);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }
  };

  // Handle place selection from search suggestions
  const handleSearchSelect = (place) => {
    const lat = place.lat;
    const lon = place.lon;
    setMarkerPosition([lat, lon]);
    setMapCenter([lat, lon]);
    fetchCityAndState(lat, lon);
    setSearchSuggestions([]); // Clear suggestions after selection
  };

  // Handle Done button to update latitude, longitude, and cityState
  const handleDoneClick = () => {
    const [lat, lng] = markerPosition;
    fetchCityAndState(lat, lng); // Fetch and set city and state
    setShowMapModal(false); // Close the map modal
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    fetchSearchResults(e.target.value);
  };

  const UpdateMarker = () => {
    const map = useMap();
    map.setView(markerPosition, map.getZoom());
    return null;
  };

  const handleInputChange = (e, fieldName) => {
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
      [`${fieldName}Error`]: "", // Clear error on input change
    }));
  };

  return (

    <div className="registration bg-gray-100 min-h-screen flex flex-col pt-5 overflow-auto">
      <main className="pt-20 pb-20 flex-1 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-1 bg-gray-100 overflow-y-auto">
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold mb-4">Restaurant information</h1>
            {/* Restaurant Name */}
            <div className="bg-white shadow-lg rounded-lg p-5 w-full">
              <h2 className="text-2xl font-bold ">Restaurant name</h2>
              <p className="text-gray-600 font-medium text-sm mb-2">
                Customers will see this name on {APP_NAME}
              </p>
              <hr />
              <form className="mt-3">
                <input type="text" value={state.restaurantName} onChange={(e) => handleInputChange(e, "restaurantName")}
                  className={`mt-2 p-2 w-full rounded-lg border ${state.restaurantNameError ? 'border-red-500' : 'border-gray-300'} outline-none shadow-sm sm:text-sm`}
                  placeholder="Restaurant name*" maxLength="20" disabled={state.inputsDisabled} />
                {state.restaurantNameError && (
                  <p className="text-red-500 text-sm mt-1">
                    {state.restaurantNameError}
                  </p>
                )}
              </form>
            </div>

            {/* Owner  Details*/}

            <div className="bg-white shadow-lg rounded-lg p-5 w-full mt-4">
              <h2 className="text-2xl font-bold">Owner details</h2>
              <p className="text-sm font-medium text-gray-600 mb-2">
                {APP_NAME} will use these details for all business
                communications and updates
              </p>
              <hr />
              <form className="mt-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <input type="text" value={state.ownerName} onChange={(e) => handleInputChange(e, "ownerName")}
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Full name*" disabled={state.inputsDisabled} />
                    {state.ownerNameError && (
                      <p className="text-red-500 text-sm mt-1">{state.ownerNameError}</p> // Error message in red
                    )}
                  </div>
                  <div>
                    <input type="email" value={state.ownerEmail} onChange={(e) => handleInputChange(e, "ownerEmail")}
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Email Address*" disabled={state.inputsDisabled} />
                    {state.ownerEmailError && (
                      <p className="text-red-500 text-sm mt-1">{state.ownerEmailError}</p> // Email error message in red
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="text-lg font-medium">
                    Restaurant's  contact number
                  </label>
                  <p className="text-sm text-gray-500 mb-2">
                    Customers, delivery partners, and {APP_NAME} may call on this
                    number for order support </p>
                  <div className="relative flex items-center border border-gray-300 outline-none shadow-sm sm:text-sm rounded p-2">
                    <div className="flex items-center">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png"
                        alt="India Flag" className="w-6 h-4 mr-2" />
                      <span className="text-gray-700">+91</span>
                    </div>
                    <input type="tel" placeholder="Phone number*" value={state.restaurantPhoneNumber}
                      onChange={handlePhoneNumberChange} minLength="10" maxLength="10"
                      className="ml-2 outline-none flex-1 border-l border-gray-300 pl-2" required disabled={state.isPhoneFetched || state.inputsDisabled} />
                    {state.restaurantPhoneNumber.length === 10 && (
                      <button
                        className={`verify-btn absolute right-2  text-white ml-2 px-3 py-1 rounded ${state.isOtpVerified ? 'bg-green-500' : ''}text-sm`}
                        onClick={handleVerifyButtonClick} disabled={state.isOtpVerified}>
                        {state.isOtpVerified ? "Verified" : "Verify"}
                      </button>
                    )}

                  </div>
                </div>
              </form>
            </div>

            <div className="bg-white shadow-lg rounded-lg px-5 pt-5 w-full mt-4">
              <h2 className="text-2xl font-bold">Restaurant address details</h2>
              <p className="text-sm font-medium text-gray-600 mb-6">
                Address details are based on the restaurant location mentioned above
              </p>



              <form onSubmit={(e) => e.preventDefault()}>
                {/* Latitude and Longitude */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="relative">
                    <input
                      type="text"
                      value={state.latitude}
                      readOnly
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Latitude"
                    />
                    <FaCrosshairs
                      className="absolute right-3 mt-1 top-3 text-green-500 text-xl cursor-pointer"
                      onClick={handleShowMap} // Handle map click for latitude and longitude update
                    />
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      value={state.longitude}
                      readOnly
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Longitude"
                    />
                  </div>
                </div>

                {/* Shop No and Floor */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Shop no"
                      value={state.shopNo}
                      maxLength={50}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow letters, numbers, and special characters, up to 50 characters
                        const regex = /^[a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>-]{0,50}$/;
                        if (regex.test(value)) {
                          handleInputChange(e, "shopNo");
                        }
                      }}
                      className={`mt-2 p-2 w-full rounded-md border ${state.shopNoError ? 'border-red-500' : 'border-gray-300'
                        } outline-none shadow-sm sm:text-sm`}
                    />
                    {state.shopNoError && (
                      <p className="text-red-500 text-sm mt-1">{state.shopNoError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Floor"
                      value={state.floor}
                      maxLength={50}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow letters, numbers, and spaces only
                        const regex = /^[a-zA-Z0-9\s]*$/;
                        if (regex.test(value)) {
                          handleInputChange(e, "floor");
                        }
                      }}
                      className={`mt-2 p-2 w-full rounded-md border ${state.floorError ? 'border-red-500' : 'border-gray-300'
                        } outline-none shadow-sm sm:text-sm`}
                    />
                    {state.floorError && (
                      <p className="text-red-500 text-sm mt-1">{state.floorError}</p>
                    )}
                  </div>
                </div>

                {/* Area and City/State */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <input
                      type="text"
                      placeholder="Area / Sector / Locality*"
                      value={state.area}
                      maxLength={50}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow letters, numbers, and spaces up to 50 characters
                        const regex = /^[a-zA-Z0-9\s]*$/;
                        if (regex.test(value)) {
                          handleInputChange(e, "area");
                        }
                      }}
                      className={`mt-2 p-2 w-full rounded-md border ${state.areaError ? 'border-red-500' : 'border-gray-300'
                        } outline-none shadow-sm sm:text-sm`}
                    />
                    {state.areaError && (
                      <p className="text-red-500 text-sm mt-1">{state.areaError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="City/State"
                      value={state.cityState}
                      maxLength={50}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow letters, numbers, and spaces up to 50 characters
                        const regex = /^[a-zA-Z0-9\s]*$/;
                        if (regex.test(value)) {
                          handleInputChange(e, "cityState");
                        }
                      }}
                      className={`mt-2 p-2 w-full rounded-md border ${state.cityStateError ? 'border-red-500' : 'border-gray-300'
                        } outline-none shadow-sm sm:text-sm`}
                    />
                    {state.cityStateError && (
                      <p className="text-red-500 text-sm mt-1">{state.cityStateError}</p>
                    )}
                  </div>
                </div>

                {/* Landmark and Zip Code */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <input
                    type="text"
                    placeholder="Add any nearby landmark (optional)"
                    value={state.landmark}
                    maxLength={50}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[a-zA-Z0-9\s]*$/; // Only letters, numbers, spaces
                      if (regex.test(value)) {
                        handleInputChange(e, "landmark");
                      }
                    }}
                    className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                  />
                  <input
                    type="text"
                    placeholder="ZipCode"
                    value={state.zipCode}
                    maxLength={6}
                    onChange={(e) => handleInputChange(e, "zipCode")}
                    className={`mt-2 p-2 w-full rounded-md border ${state.zipCodeError ? 'border-red-500' : 'border-gray-300'
                      } outline-none shadow-sm sm:text-sm`}
                  />
                  {state.zipCodeError && (
                    <p className="text-red-500 text-sm mt-1">{state.zipCodeError}</p>
                  )}
                </div>

                {/* Address Note */}
                <div>
                  <p className="address-note text-sm px-2 py-2 rounded-md mt-4">
                    Please ensure that this address is the same as mentioned on your FSSAI license
                  </p>
                </div>
              </form>


            </div>
          </div>
        </div>
      </main>

      <footer className="fixed bottom-0 w-2/3">
        {/* Save Button */}
        <div className="flex justify-center md:justify-end sm:justify-end p-4 space-x-4">
          <button
            className={`bg-slate-500 text-white py-2 px-10 rounded w-full md:w-auto h-12 flex justify-center items-center whitespace-nowrap ${state.isFormValid ? "cursor-pointer opacity-100" : "cursor-not-allowed opacity-50"}`}
            onClick={handleSaveDraft}
            disabled={!state.isFormValid} // Button is disabled if the form is invalid
          >
            Save As Draft
          </button>
          {/* Next Button */}
          <button
            className={`bg-green-600 text-white py-2 px-10 rounded w-full md:w-auto h-12 flex justify-center items-center ${state.isFormValid ? "cursor-pointer" : "cursor-not-allowed opacity-50"}`}
            onClick={handleNextClick}
            disabled={!state.isFormValid} // Prevent going to the next page if the form is invalid
          >
            Next
          </button>
        </div>
      </footer>


      {/* Rendering Required registration details page */}
      {state.showModal && <RequiredRegistrationDetails toggleModal={toggleModal} />}

      {/* Map Modal */}
      {showMapModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50 overflow-y-auto">
          <div className="bg-white rounded-lg shadow-lg w-4/5 h-4/5 p-6 relative">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Choose Location</h2>

              <div className="flex space-x-4">
                {/* Done Button */}
                <button
                  onClick={handleDoneClick} // Call the done function
                  className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded shadow-md"
                >
                  Done
                </button>

                {/* Close Button */}
                <button
                  onClick={handleCloseMap} // Call the close function
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded shadow-md"
                >
                  Close
                </button>
              </div>
            </div>

            {/* Search Input */}
            <div>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchInputChange}
                placeholder="Search for a place..."
                className="w-full p-2 rounded-md border border-gray-300 mb-2"
              />
              <ul>
                {searchSuggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSearchSelect(suggestion)}
                    className="cursor-pointer p-2 bg-gray-100 mb-1 rounded"
                  >
                    {suggestion.display_name}
                  </li>
                ))}
              </ul>
            </div>

            <div className="overflow-y-auto">
              <MapContainer
                center={mapCenter}
                zoom={13}
                style={{ height: "400px", width: "100%" }}
                whenCreated={(map) => map.on('click', (e) => {
                  const { lat, lng } = e.latlng;
                  setMarkerPosition([lat, lng]);  // Update marker position when clicking on the map
                  setMapCenter([lat, lng]);  // Optionally, update the map center if needed
                })}
              >


                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker
                  position={markerPosition}
                  draggable={true}
                  eventHandlers={{
                    dragend: (e) => {
                      const lat = e.target.getLatLng().lat;
                      const lng = e.target.getLatLng().lng;
                      setMarkerPosition([lat, lng]);
                    },
                  }}
                />
                <UpdateMarker />
              </MapContainer>


            </div>

          </div>
        </div>
      )}


      {state.showReferModal && (
        <ReferrerModal
          toggleReferModal={toggleReferModal}
          onClickSuccess={() => {
            toggleReferModal();
          }}
        />
      )}

      {state.showOtpModal && (
        // Otp verification section
        <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg shadow-lg w-96 p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">OTP Verification</h2>
              <button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showOtpModal: false,
                  }))
                }
                className="text-gray-500 hover:text-gray-700"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="text-center">
              <p className="text-gray-500">Check text messages for your OTP</p>
              <div className="flex justify-center mt-4 space-x-2">
                {state.otp.map((digit, index) => (
                  <input key={index} type="text" maxLength="1"
                    className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                    value={digit} onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(el) => inputRefs.current[index] = el} disabled={state.isOtpVerified || state.inputsDisabled}
                  />
                ))}
              </div>
              <div className="mt-4">
                <button
                  className={`bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded ${state.otp.join('').length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={handleVerifyOtp}
                  disabled={state.otp.join('').length !== 6}
                >
                  Verify OTP
                </button>
              </div>
              {/* Don't delete this */}
              {/* Error Message Display */}
              {/* {state.otpError && (
                <p className="text-red-500 mt-2">{state.otpError}</p> // Error message in red
              )} */}
              <div className="mt-2 text-gray-500">
                Not received OTP? <a href="#" className="text-red-500 hover:underline" onClick={handleResendOtp} >Resend Now</a>
              </div>
            </div>
          </div>
        </div>)
      }
      {
        state.showLogoutModal && (
          <LogoutModel
            onClickLogout={confirmLogout}
            onClickCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showLogoutModal: false,
              }))
            }
          />
        )
      }
    </div >

  );
};
export default Registration;
