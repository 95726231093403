import React from 'react';
import restaurent from '../images/profile.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const RestaurentProfileImages = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="fixed inset-0" onClick={onClose}></div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl w-full max-w-lg mx-4 sm:mx-auto">
          <div className="p-4 border-b flex justify-between items-center">
            <h2 className="text-lg font-bold">Image upload guidelines</h2>
            <button className="cursor-pointer text-2xl" onClick={onClose}>
              <FontAwesomeIcon icon={faXmark} className="cursor-pointer" />
            </button>
          </div>
          <div className="p-4">
            <ul className="space-y-4">
              <li className="flex items-center space-x-4">
                <img
                  src={restaurent}
                  alt="Guideline 1"
                  className="w-28 h-20 object-cover sm:w-32 sm:h-24"
                />
                <span className="text-sm sm:text-base">
                  Make sure all details <br /> are visible.
                </span>
              </li>
              <li className="flex items-center space-x-4">
                <img
                  src={restaurent}
                  alt="Guideline 2"
                  className="w-28 h-20 object-cover sm:w-32 sm:h-24"
                />
                <span className="text-sm sm:text-base">Image should not be blurry.</span>
              </li>
              <li className="flex items-center space-x-4">
                <img
                  src={restaurent}
                  alt="Guideline 3"
                  className="w-28 h-20 object-cover sm:w-32 sm:h-24"
                />
                <span className="text-sm sm:text-base">Image should not be cropped.</span>
              </li>
            </ul>
          </div>
          <div className="p-4 border-t flex justify-end">
            <button
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
              onClick={onClose}
            >
              Upload now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurentProfileImages
