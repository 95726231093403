import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TrackLastPage = () => {
  const location = useLocation();

  useEffect(() => {
    // Store the current path in localStorage
    localStorage.setItem('lastVisitedPage', location.pathname);
  }, [location]);

  return null; // This component does not render anything
};

export default TrackLastPage;
